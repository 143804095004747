import { useCallback } from 'react';
import { useApprove, useDocumentsReady } from 'entities/Document/hooks';
import { useStore } from 'entities/User/hooks';
import { Button, Typography } from 'shared/ui';

interface ApproveProps {
  workIds?: string | string[];
}

export const Approve = ({ workIds }: ApproveProps) => {
  const userStatus = useStore((state) => state.value.approval_status);
  const { loading: loadingDocs, ready } = useDocumentsReady(workIds);
  const approved = userStatus === 'approved';
  const waiting =
    (userStatus === 'checking' || userStatus === 'requested') && !workIds;
  const canApprove = (!waiting && !loadingDocs && ready) || !!workIds;
  const { loading: posting, value, error, perform } = useApprove();
  const handleApprove = useCallback(() => {
    perform(
      workIds ? (Array.isArray(workIds) ? workIds : [workIds]) : undefined
    );
  }, [perform, workIds]);
  //
  if (approved) return null;
  if (loadingDocs) return null;
  if (value)
    return <Typography.Text>Документы отправлены на проверку</Typography.Text>;
  if (waiting) return null;
  if (!canApprove)
    return (
      <Typography.Text color="error">
        Для запроса аккредитации необходимо заполнить все формы
      </Typography.Text>
    );
  return (
    <>
      <Button color="primary" loading={posting} onClick={handleApprove}>
        Запросить аккредитацию
      </Button>
      {error ? <Typography.Text color="error">{error}</Typography.Text> : null}
    </>
  );
};
