import { useMemo } from 'react';
import { useWorks } from './useWorks';

export const useWorksFolders = () => {
  const { loading, value } = useWorks();
  const index = useMemo(() => {
    if (!value) return {};
    return value.reduce((result, item) => {
      result[item.id] = item;
      return result;
    }, {});
  }, [value]);
  const folders = useMemo(() => {
    if (!value) return {};
    return value.reduce((result, item) => {
      let folder = item;
      while (!!folder) {
        if (folder.is_folder && !folder.parent) {
          result[item.id] = folder;
          break;
        }
        folder = index[folder.parent];
      }
      return result;
    }, {});
  }, [value, index]);
  return { loading, value, index, folders };
};
