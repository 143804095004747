import { useDocuments as useDocumentsSource } from 'entities/Document/hooks';
//import { List } from 'entities/Document';
//import { Approve } from 'features/Document';
import { useWorksFolders } from 'entities/Work';
import { useCallback, useMemo } from 'react';

const param: unknown = undefined;

export const useDocuments = () => {
  const {
    loading: documentsLoading,
    value: documents,
    perform: fetchDocuments,
  } = useDocumentsSource();
  const {
    loading: worksLoading,
    value: works,
    index: indexWorks,
    folders: indexFolders,
  } = useWorksFolders();
  const loading = documentsLoading || worksLoading;
  const onRefresh = useCallback(() => {
    fetchDocuments(param);
  }, [fetchDocuments]);
  const indexDocuments = useMemo(
    () =>
      documents?.reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {}),
    [documents]
  );
  return {
    loading,
    documents,
    indexDocuments,
    works,
    indexWorks,
    indexFolders,
    onRefresh,
  };
};
