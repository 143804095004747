import type { Document, Work } from 'shared/types';

interface CalcGroupsProps {
  documents?: Document[];
  indexDocuments?: Record<string, Document>;
  works?: Work[];
  indexWorks?: Record<string, Work>;
  indexFolders?: Record<string, Work>;
}

export interface DocumentsGroupItem {
  workIds: string[];
  document: Document;
  required: boolean;
}

export interface DocumentsGroup {
  id: string;
  caption: string;
  items: DocumentsGroupItem[];
  workIds: string[];
  canApprove?: boolean;
  inProgress?: boolean;
  rejected?: boolean;
}

export type FolderedWorks = Record<
  string,
  {
    items: string[];
    index: Record<string, boolean>;
  }
>;

const getIsRejected = (items: DocumentsGroupItem[]) =>
  !!items.find((item) => item.document.status === 'rejected');

const getIsChecking = (items: DocumentsGroupItem[]) =>
  !!items.find((item) => item.document.status === 'checking');

const getInProgress = (items: DocumentsGroupItem[]) =>
  !!items.find(
    (item) =>
      item.document.status === 'rejected' ||
      item.document.status === 'not_present'
  ) && getIsChecking(items);

const getCanApprove = (items: DocumentsGroupItem[]) =>
  !getIsRejected(items) &&
  !getIsChecking(items) &&
  !!items.find((item) => item.document.status === 'applied') &&
  !items.find((item) => item.document.status === 'not_present');

export const isCommonPresent = (ids?: string[]) => {
  return (
    (ids?.includes('all') || ids?.includes('ip') || ids?.includes('org')) ??
    false
  );
};

export const isWorkPresent = (workId: string, ids?: string[]) => {
  return ids?.includes(workId) ?? false;
};

export const calcGroups = ({
  documents,
  indexDocuments,
  works,
  indexWorks,
  indexFolders,
}: CalcGroupsProps) => {
  const selectedWorks = works
    ?.filter((item) => item.selected)
    .map((item) => item.id);
  //
  const folderedWorks = selectedWorks?.reduce((result, id) => {
    const folderId = indexFolders?.[id]?.id;
    if (folderId) {
      if (!result[folderId]) result[folderId] = { items: [], index: {} };
      result[folderId].items.push(id);
      result[folderId].index[id] = true;
    }
    return result;
  }, {} as FolderedWorks);
  const foldersIds = Object.keys(folderedWorks || {});

  const groups = {} as Record<string, DocumentsGroup>;
  if (folderedWorks) {
    documents?.forEach((document) => {
      // common documents
      const required = isCommonPresent(document?.condition_sets_mandatory);
      const notRequired = isCommonPresent(
        document?.condition_sets_non_mandatory
      );
      if (required || notRequired) {
        foldersIds.forEach((folderId) => {
          const workIds = folderedWorks[folderId].items;
          let group = groups[folderId];
          if (!group) {
            const caption = indexWorks?.[folderId]?.name;
            group = groups[folderId] = {
              id: folderId,
              caption,
              items: [],
              workIds: folderedWorks[folderId].items,
            } as DocumentsGroup;
          }
          group.items.push({ required, document, workIds });
        });
      } else {
        // documents by workIds
        foldersIds.forEach((folderId) => {
          const workIds = folderedWorks[folderId].items;
          workIds.forEach((workId) => {
            const required = isWorkPresent(
              workId,
              document?.condition_sets_mandatory
            );
            const notRequired = isWorkPresent(
              workId,
              document?.condition_sets_non_mandatory
            );
            if (required || notRequired) {
              // check the group
              let group = groups[folderId];
              if (!group) {
                const caption = indexWorks?.[folderId]?.name;
                group = groups[folderId] = {
                  id: folderId,
                  caption,
                  items: [],
                  workIds: folderedWorks[folderId].items,
                } as DocumentsGroup;
              }
              // check the document
              let documentItem = group.items.find(
                (item) => item.document.id === document.id
              );
              if (!documentItem) {
                documentItem = { required, document, workIds: [workId] };
                group.items.push(documentItem);
              } else {
                if (required) {
                  documentItem.required = true;
                }
                if (!documentItem.workIds.includes(workId)) {
                  documentItem.workIds.push(workId);
                }
              }
            }
          });
          //
        });
      }
    });
  }
  const list = Object.keys(groups).map((key) => {
    const result = groups[key];
    result.canApprove = getCanApprove(result.items);
    result.inProgress = getInProgress(result.items);
    result.rejected = getIsRejected(result.items);
    return result;
  });
  return list;
};
