import { Button } from 'shared/ui';

import { Works as View } from 'entities/Work';
import { useWorks } from 'entities/Work/hooks';
import type { Work } from 'shared/types';
import { useEditorIfEmpty } from 'shared/hooks';

const isFilled = (value?: Work[]) => {
  return true;
};

const href = 'edit/';

export const Works = () => {
  const { loading, value } = useWorks();
  useEditorIfEmpty({ loading, value, href, check: isFilled });

  return (
    <View
      loading={loading}
      value={value}
      action={
        <Button href={href} color="primary">
          Редактировать
        </Button>
      }
    ></View>
  );
};
