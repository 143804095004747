import { useCallback, useState } from 'react';
import { useContextBus } from 'shared/hooks/useContextBus';
import { api } from 'shared/api';
import { Context } from '../model';

export const useDocumentDelete = (id?: string, onComplete?: () => void) => {
  const [loading, setLoading] = useState(false);
  const { post } = useContextBus(Context);
  const onDelete = useCallback(() => {
    setLoading(true);
    (async () => {
      if (!id) return;
      try {
        await api.deleteFile({ id });
        post('refresh');
        onComplete && onComplete();
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [id, onComplete, post]);
  return {
    loading,
    onDelete,
  };
};
