import clsx from 'clsx';
import { CONTACTS } from '../../../../constants';
import { Logo, Typography } from 'shared/ui';

import telegram from 'shared/icons/telegram.svg';
import youtube from 'shared/icons/youtube.svg';
import vk from 'shared/icons/vk.svg';

import styles from './Footer.module.css';

export const Footer = () => {
  return (
    <footer className={styles.container}>
      <a className={styles.logo} href="https://glavstroy-r.ru/">
        <Logo.Image />
      </a>
      <div className={styles.links}>
        <div className={styles.socials}>
          <div className={styles.social}>
            <img src={telegram} alt="Telegram" />
          </div>
          <div className={styles.social}>
            <img src={youtube} alt="Youtube" />
          </div>
          <div className={styles.social}>
            <img src={vk} alt="VK" />
          </div>
        </div>
        <Typography.Text view="primary-large">
          <a className={styles.phone} href={`tel:${CONTACTS.HOTLINE.PHONE}`}>
            {CONTACTS.HOTLINE.FORMATTED}
          </a>
        </Typography.Text>
      </div>
      <div className={clsx(styles.logo, styles.mirror)}>
        <Logo.Image />
      </div>
    </footer>
  );
};
