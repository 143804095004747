import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FormEventHandler,
} from 'react';

import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

import { useWorks } from 'entities/Work/hooks';
import { UpdateEntityRequest } from 'shared/types';
import { useStore } from 'entities/Entity/hooks';
import { useProfile } from 'entities/User';

export const useEditor = (onRefresh: () => void) => {
  const { loading, value: sourceWorks } = useWorks();
  const sourceEntity = useStore((state) => state.value);
  const profile = useProfile();
  const readonly = useMemo(() => {
    return !(
      profile?.value?.name &&
      profile?.value.surname &&
      profile?.value?.givenname &&
      profile?.value?.phone &&
      profile?.value?.mobile_phone
    );
  }, [profile]);

  const [works, setWorks] = useState([...(sourceWorks || [])]);
  const [value, setValue] = useState<UpdateEntityRequest>({
    ...(sourceEntity || {}),
    selected_works: [],
  } as unknown as UpdateEntityRequest);
  useEffect(() => {
    if (sourceWorks) setWorks([...sourceWorks]);
  }, [sourceWorks]);
  //
  useEffect(() => {
    if (works) setWorks(works);
    setValue((current) => ({
      ...current,
      selected_works: works
        .filter((item) => item.selected)
        .map((item) => item.id),
    }));
  }, [works]);
  //
  useEffect(() => {
    setValue((current) => {
      return {
        ...current,
        ...(sourceEntity || {}),
      };
    });
  }, [sourceEntity]);
  //
  const onToggle = useCallback((id: string) => {
    setWorks((current) =>
      [...current].map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  }, []);
  const {
    loading: posting,
    perform,
    value: updated,
    error,
  } = useQuery(api.updateEntity);
  const onSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      perform(value);
    },
    [value, perform]
  );
  useEffect(() => {
    if (updated && onRefresh) onRefresh();
  }, [updated, onRefresh]);
  return {
    error,
    loading,
    readonly,
    posting,
    works,
    value,
    onToggle,
    onChange: setValue,
    onSubmit,
  };
};
