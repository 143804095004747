import { ReactNode } from 'react';
import { DateTime, Typography } from 'shared/ui';

import type { User } from '../../model';
import { getStatusString } from '../../lib';

import styles from './Profile.module.css';

interface ProfileProps {
  loading: boolean;
  value: User;
  action: ReactNode;
}

export const Profile = ({ loading, value, action }: ProfileProps) => {
  return (
    <>
      <div>
        <Typography.Label>Логин</Typography.Label>
        <Typography.Text>{value.login}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Электронная почта</Typography.Label>
        <Typography.Text>{value.email}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Резервная почта</Typography.Label>
        <Typography.Text>{value.additional_email}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Фамилия</Typography.Label>
        <Typography.Text>{value.name || '-'}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Имя</Typography.Label>
        <Typography.Text>{value.givenname || '-'}</Typography.Text>
      </div>
      {value.surname ? (
        <div>
          <Typography.Label>Отчество</Typography.Label>
          <Typography.Text>{value.surname}</Typography.Text>
        </div>
      ) : null}
      <div>
        <Typography.Label>Телефон</Typography.Label>
        <Typography.Text>{value.phone || '-'}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Мобильный телефон</Typography.Label>
        <Typography.Text>{value.mobile_phone || '-'}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Аккредитация</Typography.Label>
        <Typography.Text>
          {getStatusString(value.approval_status)}{' '}
          {value.approval_ends ? (
            <>
              до <DateTime value={value.approval_ends} />
            </>
          ) : null}
        </Typography.Text>
      </div>
      <div className={styles.actions}>{action}</div>
    </>
  );
};
