import { useMemo } from 'react';
import { Item } from 'entities/Work/ui/Item';
import { Item as Document } from 'entities/Document/ui/Item';
import { Approve } from 'features/Document';
//import { useDocuments } from 'entities/Document/hooks';

//import { List } from 'entities/Document';
//import { Approve } from 'features/Document';
//import { useWorksFolders } from 'entities/Work';

import { calcGroups } from 'widgets/Document/lib/calcGroups';
import { useDocuments } from '../../hooks/useDocuments';
import { Content, Frame, List } from 'shared/ui';

export const Documents = () => {
  const {
    loading,
    works,
    indexDocuments,
    documents,
    indexWorks,
    indexFolders,
    onRefresh,
  } = useDocuments();
  console.log({
    loading,
    works,
    documents,
    indexDocuments,
    indexWorks,
    indexFolders,
    onRefresh,
  });
  const groups = useMemo(
    () =>
      calcGroups({
        works,
        indexWorks,
        documents,
        indexDocuments,
        indexFolders,
      }),
    [works, indexWorks, documents, indexDocuments, indexFolders]
  );

  return (
    <List>
      {groups.map((group, i) => (
        <div key={i}>
          <Frame>
            <Item
              id={group.id}
              level={0}
              name={group.caption}
              closed
              folder
              highlight
            />
            <List>
              {group.items.map((item, i) => (
                <Document
                  id={item.document.id}
                  docId={item.document.template_file_id}
                  fileId={item.document.file_id}
                  fileName={item.document.file_name}
                  size={item.document.max_size}
                  format={item.document.format}
                  status={item.document.status}
                  name={item.document.name}
                  approvalEnds={item.document.approval_ends}
                  required={item.required}
                  onRefresh={onRefresh}
                  rejectedReason={item.document.reject_reason}
                />
              ))}
            </List>
          </Frame>
          {group.inProgress ? (
            <Content>
              <br />
              <div>Ожидает проверки</div>
              <br />
            </Content>
          ) : null}
          {group.canApprove ? (
            <Content>
              <br />
              <Approve workIds={group.workIds} />
              <br />
            </Content>
          ) : null}
        </div>
      ))}
    </List>
  );
  /*
  
  const {
    loading: worksLoading,
    value: works,
    index,
    folders,
  } = useWorksFolders();
  const selectedItems = useMemo(
    () => works?.filter((item) => item.selected),
    [works]
  );
  const selectedIds = useMemo(
    () => selectedItems?.map((item) => item.id),
    [selectedItems]
  );
  const folderedIds = useMemo(
    () =>
      selectedIds?.reduce((result, id) => {
        const folderId = folders[id].id;
        if (!result[folderId]) result[folderId] = [];
        result[folderId].push(id);
        return result;
      }, {}),
    [selectedIds, folders]
  );
  console.log({ folderedIds });
  const handleGetCaption = useCallback(
    (id: string) => {
      return folders[id]?.name;
      // return index[id]?.name;
    },
    [folders]
  );
  const handleGetStatus = useCallback(
    (id) => index[id]?.approval_status,
    [index]
  );
  const handleRefresh = useCallback(() => {
    perform(param);
  }, [perform]);
  console.log({ value, selectedIds });
  return (
    <List
      loading={loading || worksLoading}
      items={value}
      groupIds={selectedIds}
      onRefresh={handleRefresh}
      onGetCaption={handleGetCaption}
      onGetStatus={handleGetStatus}
      action={<Approve />}
    />
  );*/
};
