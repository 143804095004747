import { useStore } from 'entities/User/hooks';
import { Logo } from 'shared/ui';
import { Hotline } from 'widgets/Contact';
import { Menu } from 'widgets/Navigation/ui/Menu';
import { HeaderElement } from 'widgets/User/ui/HeaderElement/HeaderElement';

import styles from './Header.module.css';

export const Header = () => {
  const isLoggedIn = useStore((state) => !!state.value?.login);
  return (
    <>
      <header className={styles.container}>
        <div className={styles.content}>
          <a className={styles.logo} href="https://glavstroy-r.ru/">
            <Logo.Image />
          </a>
          <Menu />
          {isLoggedIn ? <HeaderElement /> : <Hotline />}
        </div>
      </header>
      <div className={styles.stub} />
    </>
  );
};
