import { useMemo, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Work } from 'shared/types';
import { ApprovalStatus, List, Skeleton, Typography } from 'shared/ui';

import styles from './Works.module.css';
import { Item } from '../Item';

interface WorksProps {
  loading: boolean;
  value?: Work[];
  action?: ReactNode;
}

const format = (value: string) =>
  value.split('T')[0].split('-').reverse().join('.');

export const Works = ({ loading, value, action }: WorksProps) => {
  const index = useMemo(() => {
    if (!value) return {};
    return value.reduce((result, item) => {
      result[item.id] = item;
      return result;
    }, {});
  }, [value]);
  const folders = useMemo(() => {
    if (!value) return {};
    return value.reduce((result, item) => {
      let folder = item;
      while (!!folder) {
        if (folder.is_folder && !folder.parent) {
          result[item.id] = folder;
          break;
        }
        folder = index[folder.parent];
      }
      return result;
    }, {});
  }, [value, index]);
  //
  const items = useMemo(
    () =>
      value
        ? value
            .filter((item) => item.selected)
            .map((item) => ({
              name: item.name,
              status: item.approval_status,
              approval_ends: item.approval_ends,
              parent: item.parent,
              folder: folders[item.id],
            }))
        : undefined,
    [value, folders]
  );
  const groups = useMemo(() => {
    const index = items?.reduce((result, item) => {
      if (item.folder) {
        if (!result[item.folder.id]) result[item.folder.id] = [];
        result[item.folder.id].push(item);
      }
      return result;
    }, {});
    const groups = index
      ? Object.keys(index).map((key) => {
          const items = index[key];
          return {
            name: folders[key]?.name,
            items,
          };
        })
      : [];
    return groups;
  }, [items, folders]);
  const loadingItems = useMemo(() => {
    if (!loading) return;
    return [20, 18, 25, 10];
  }, [loading]);
  const isEmpty = useMemo(() => {
    return groups?.length === 0;
  }, [groups]);
  const children = useMemo(() => {
    if (loading)
      return loadingItems?.map((item, i) => (
        <div className={styles.item} key={i}>
          <Skeleton active width={item}>
            xx
          </Skeleton>
        </div>
      ));
    return null;
  }, [loading, loadingItems]);
  if (loading)
    return (
      <>
        <List className={styles.list}>{children}</List>
        <div className={styles.actions}>{action}</div>
      </>
    );
  if (isEmpty && !loading)
    return (
      <>
        <Typography.Text>Нет выбранных работ</Typography.Text>
        <div className={styles.actions}>{action}</div>
      </>
    );
  return (
    <>
      {groups.map((group, i) => (
        <List className={styles.list} key={i}>
          <Item
            className={styles.groupName}
            name={group.name}
            id={group.name}
            level={0}
            folder
            closed
          />
          {group.items?.map((item, i) => (
            <Link to="/entity/documents/" className={styles.item} key={i}>
              <Typography.Text size="large">{item.name}</Typography.Text>
              <Typography.Text size="medium">
                <ApprovalStatus
                  before={
                    item.approval_ends ? format(item.approval_ends) : undefined
                  }
                >
                  {item.status}
                </ApprovalStatus>
              </Typography.Text>
            </Link>
          ))}
        </List>
      ))}
      <div className={styles.actions}>{action}</div>
    </>
  );
};
