import {
  useCallback,
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
  type ChangeEventHandler,
  type PropsWithChildren,
  FormEventHandler,
} from 'react';
import { Button, Content, Field, Input, Typography } from 'shared/ui';
import type { UpdateEntityRequest } from 'shared/types';

import { useInnLookup } from '../../hooks';

interface EditorProps {
  error?: string;
  posting?: boolean;
  loading: boolean;
  readonly?: boolean;
  value: UpdateEntityRequest;
  onChange: Dispatch<SetStateAction<UpdateEntityRequest>>;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

import styles from './Editor.module.css';
import { getInnErrorText } from 'entities/Entity/lib/inn';

const EMPTY = {
  full_name: '',
  kpp: '',
  legal_addr: '',
  ogrn: '',
  post_addr: '',
  selected_works: [],
  short_name: '',
  type: 0,
  head: '',
  homepage: '',
  phone: '',
};

export const Editor = ({
  error,
  posting,
  loading,
  readonly,
  value,
  onChange,
  onSubmit,
  children,
}: PropsWithChildren<EditorProps>) => {
  const [innChanged, setInnChanged] = useState(false);
  const [postIsLegal, setPostIsLegal] = useState(false);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { name, value, checked } = e.currentTarget;
      let postIsLegal = false;
      if (name === 'post_is_legal') {
        postIsLegal = checked || false;
        setPostIsLegal(checked);
      }
      if (name === 'inn') {
        setInnChanged(true);
        onChange(
          () =>
            ({
              [name]: value,
              ...EMPTY,
            } as UpdateEntityRequest)
        );
      }
      onChange((current) => {
        const result = {
          ...current,
          [name]: name === 'type' ? +value : value,
        };
        if (postIsLegal) {
          result.post_addr = result.legal_addr;
        }
        return result;
      });
    },
    [onChange, setInnChanged]
  );

  const {
    innError,
    loading: innLoading,
    entity,
  } = useInnLookup(innChanged ? value.inn : '');

  useEffect(() => {
    if (entity)
      onChange((current) => ({
        ...current,
        ...entity,
      }));
  }, [entity, onChange]);

  const dataLoading = innLoading || loading;

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      <Content inplace>
        <Typography.Title size="xsmall">Сведения</Typography.Title>
        <Field
          name="inn"
          caption="ИНН"
          loading={loading}
          readOnly={readonly}
          error={innError !== 'empty' ? getInnErrorText(innError) : undefined}
          required
          value={value.inn || ''}
          onChange={handleChange}
        />
        <div>
          <Typography.Label>Тип организации</Typography.Label>
          <div className={styles.radios}>
            <Typography.Label className={styles.check}>
              <Input
                className={styles.checkInput}
                name="type"
                type="radio"
                checked={+value.type === 1}
                readOnly={readonly}
                value={1}
                onChange={handleChange}
              />{' '}
              <span>Юридическое лицо</span>
            </Typography.Label>
            <Typography.Label className={styles.check}>
              <Input
                className={styles.checkInput}
                name="type"
                type="radio"
                readOnly={readonly}
                checked={+value.type === 2}
                value={2}
                onChange={handleChange}
              />{' '}
              <span>Индивидуальный предприниматель ОСН</span>
            </Typography.Label>
            <Typography.Label className={styles.check}>
              <Input
                className={styles.checkInput}
                name="type"
                type="radio"
                readOnly={readonly}
                checked={+value.type === 3}
                value={3}
                onChange={handleChange}
              />{' '}
              <span>Индивидуальный предприниматель УСН</span>
            </Typography.Label>
          </div>
        </div>
        <Field
          name="full_name"
          caption="Полное наименование"
          loading={dataLoading}
          readOnly={readonly}
          required
          value={value.full_name || ''}
          onChange={handleChange}
        />
        <Field
          name="short_name"
          caption="Краткое наименование"
          loading={dataLoading}
          required
          readOnly={readonly}
          value={value.short_name || ''}
          onChange={handleChange}
        />
        <Field
          name="kpp"
          caption="КПП"
          loading={dataLoading}
          required
          readOnly={readonly}
          value={value.kpp || ''}
          onChange={handleChange}
        />
        <Field
          name="ogrn"
          caption="ОГРН"
          loading={dataLoading}
          required
          readOnly={readonly}
          value={value.ogrn || ''}
          onChange={handleChange}
        />
        <Field
          name="legal_addr"
          caption="Юридический адрес"
          loading={dataLoading}
          required
          readOnly={readonly}
          value={value.legal_addr || ''}
          onChange={handleChange}
        />
        <Typography.Label className={styles.check}>
          <Input
            className={styles.checkInput}
            name="post_is_legal"
            type="checkbox"
            checked={postIsLegal}
            readOnly={readonly}
            onChange={handleChange}
          />{' '}
          <span>Адрес исполнительного органа совпадает с юридическим</span>
        </Typography.Label>
        <Field
          name="post_addr"
          caption="Адрес исполнительного органа"
          loading={dataLoading}
          readOnly={postIsLegal || readonly}
          required
          value={value.post_addr || ''}
          onChange={handleChange}
        />
        <Field
          name="homepage"
          caption="Сайт"
          loading={dataLoading}
          readOnly={readonly}
          value={value.homepage || ''}
          onChange={handleChange}
        />
        <Field
          name="phone"
          caption="Телефон"
          loading={dataLoading}
          readOnly={readonly}
          required
          value={value.phone || ''}
          onChange={handleChange}
        />
        <Field
          name="head"
          caption="ФИО руководителя"
          loading={dataLoading}
          required
          readOnly={readonly}
          value={value.head || ''}
          onChange={handleChange}
        />
        <div className={styles.actions}>
          <Button
            color="primary"
            type="submit"
            loading={posting}
            disabled={readonly}
          >
            Сохранить
          </Button>
        </div>
        {error ? (
          <Typography.Text color="error">{error}</Typography.Text>
        ) : null}
      </Content>
    </form>
  );
};
