// import { ChangeEventHandler, useCallback } from 'react';
import { Action } from 'shared/ui';
// import styles from './UploadAction.module.css';

interface UploadActionProps {
  loading?: boolean;
  onDelete?: () => void;
}

export const DeleteAction = ({ loading, onDelete }: UploadActionProps) => {
  return (
    <div>
      <Action icon="delete" loading={loading} onClick={onDelete} />
    </div>
  );
};
