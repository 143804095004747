import { useMemo } from 'react';
import { useDocuments } from './useDocuments';

const checkWorkIds = (set: string[], workIds?: string | string[]) => {
  if (!workIds) return false;
  if (!Array.isArray(workIds)) workIds = [workIds];
  return workIds.reduce((result, workId) => {
    if (result) return result;
    return set.includes(workId);
  }, false);
};

export const useDocumentsReady = (workIds?: string | string[]) => {
  const { value, loading } = useDocuments();
  const neededDocs = useMemo(() => {
    if (!value) return value;
    return value.filter((item) => {
      const condition_sets = [...(item.condition_sets_mandatory || [])];
      return (
        condition_sets.includes('all') ||
        condition_sets.includes('ip') ||
        condition_sets.includes('org') ||
        checkWorkIds(condition_sets, workIds)
      );
    });
  }, [workIds, value]);
  const result = useMemo(
    () => ({
      loading,
      ready: loading
        ? false
        : neededDocs
        ? neededDocs.filter(
            (item) => item.status === 'applied' || item.status === 'approved'
          ).length === neededDocs.length
        : false,
    }),
    [loading, neededDocs]
  );
  return result;
};
